/* eslint-disable import/order */
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { MetaMenuAuthRouteObject } from '@/routes';
import { useAppSelector } from '@/store/hooks';
import { getDefaultOrganization } from '@/store/user';
import { AuthenticationFlow } from '../AuthenticationFlow';
import { RemoveTrailingSlash } from '../RemoveTrailingSlash';
import { RequireAuth } from '../RequireAuth';
import styles from './AuthLayout.module.less';
import { LayoutHeader } from './Header/LayoutHeader';
import { LeftSider } from './Sider/LeftSider';

const { Content } = Layout;

interface LayoutProps {
  // default routers
  routers?: MetaMenuAuthRouteObject[];
  // menu routers
  authRouters?: MetaMenuAuthRouteObject[];
}

const MainLayout = (props: LayoutProps) => {
  const defaultOrganization = useAppSelector(getDefaultOrganization());

  return (
    <>
      <RemoveTrailingSlash />
      <RequireAuth>
        <AuthenticationFlow>
          <Layout className={styles.mainContainer}>
            <LayoutHeader />
            <Layout>
              {defaultOrganization && <LeftSider />}
              <Layout>
                <Content className={styles.contentContainer}>
                  <Outlet />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </AuthenticationFlow>
      </RequireAuth>
    </>
  );
};

export default MainLayout;
